import React from 'react';

import { SelectInput } from '~/components/input';
import states from '~/fixtures/states';

interface Props {
	onChange: (e) => {};
	value?: string;
	narrow?: boolean;
	forceNativeFallback?: boolean;
}

const shortHandStates = states.map(({ value }) => ({
	label: value,
	value
}));

const StateSelect: React.FC<Props> = (props) => (
	<div>
		<SelectInput
			className="state-select fs-exclude"
			placeholder={props.narrow ? 'State' : 'Select your state'}
			blurInputOnSelect={true}
			label="State"
			name="state"
			options={shortHandStates}
			isSearchable={true}
			showNativeFallback
			{...props}
		/>
	</div>
);

export default StateSelect;
