import React from 'react';
import styled from 'styled-components';

import { StateSelect, TextInput } from '~/components/input';
import { AddressDetails } from '~/types';
import { phoneMask } from '~/utils/masks';

interface Props {
	onChange: (e: AddressDetails) => {};
	value: AddressDetails;
	includePhone?: boolean;
}

const AddressInput: React.FC<Props> = (props) => (
	<AddressInputStyles>
		<TextInput
			value={props.value?.street1}
			name="address_1"
			className="fs-exclude"
			label="Address Line 1"
			placeholder="1234 Street Rd"
			onChange={(e) => props.onChange({ street1: e.target.value })}
		/>
		<TextInput
			value={props.value?.street2}
			name="address_2"
			label="Address Line 2"
			className="fs-exclude"
			placeholder="Apt 12"
			onChange={(e) => props.onChange({ street2: e.target.value })}
		/>

		<div className="city-state">
			<TextInput
				value={props.value?.city}
				label="City"
				name="city"
				className="fs-exclude"
				placeholder="Any Town"
				onChange={(e) => props.onChange({ city: e.target.value })}
			/>
			<StateSelect
				narrow
				value={props.value?.state}
				onChange={(state) => props.onChange({ state: state })}
			/>
		</div>

		<div className="zip-phone">
			<TextInput
				value={props.value?.zip}
				label="Zip Code"
				name="zip"
				className="fs-exclude"
				placeholder="12345"
				maxLength={5}
				onChange={(e) => props.onChange({ zip: e.target.value })}
			/>

			{props.includePhone && (
				<TextInput
					autoFocus
					name="phone"
					label="Phone"
					className="fs-exclude"
					type="tel"
					placeholder="888-123-4567"
					placeholderChar={'\u2000'}
					mask={phoneMask}
					value={props.value?.phone}
					onChange={(e) => props.onChange({ phone: e.target.value })}
				/>
			)}
		</div>
	</AddressInputStyles>
);

const AddressInputStyles = styled.div`
	.text-input {
		input {
			max-width: 350px;
			width: 350px;
		}
	}

	.city-state {
		display: flex;
		> div {
			:first-of-type input {
				width: 250px;
				max-width: 250px;
				margin-right: 1rem;
			}
		}

		.state-select > div {
			height: 40px !important;
		}

		@media (max-width: 896px) {
			flex-direction: column;
			> div {
				:first-of-type input {
					width: 350px;
					max-width: 350px;
				}
			}
		}
	}

	.zip-phone {
		display: flex;
		flex-direction: row;
		> div {
			:first-of-type input {
				width: 105px;
				max-width: 105px;
				margin-right: 1rem;
			}

			:last-of-type input {
				width: 230px;
			}
		}

		@media (max-width: 896px) {
			flex-direction: column;
			> div {
				:first-of-type input {
					width: 350px;
					max-width: 350px;
				}
			}
		}
	}
`;

export default AddressInput;
